import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import { Table, Button, Modal, Form, Select, message } from 'antd';
import { ApiAuth } from 'src/shared/api/ApiAuth';
import type { GroupUser } from 'src/shared/types/groups';
import { groupsApi } from 'src/shared/api/groupsApi';

interface MembersTabProps {
  groupId: number;
  members: GroupUser[];
  onUpdate: () => void;
}

export const MembersTab: FC<MembersTabProps> = ({ groupId, members, onUpdate }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [users, setUsers] = useState<GroupUser[]>([]);
  const [form] = Form.useForm();

  const fetchUsers = async () => {
    try {
      const { data } = await groupsApi.getUsers();
      setUsers(data);
    } catch (error) {
      message.error('Ошибка при загрузке пользователей');
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const columns = [
    { title: 'Имя', dataIndex: 'full_name', key: 'full_name' },
    { title: 'Логин', dataIndex: 'user_name', key: 'user_name' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Роль', dataIndex: 'role', key: 'role' },
    {
      title: 'Действия',
      key: 'actions',
      render: (record: GroupUser) => (
        <Button danger onClick={() => handleRemoveMember(record.id)}>
          Удалить
        </Button>
      )
    }
  ];

  const handleAddMember = async (values: { user_id: number }) => {
    try {
      await groupsApi.addGroupMember(groupId, values.user_id);
      message.success('Участник добавлен успешно');
      onUpdate();
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      message.error('Ошибка при добавлении участника');
    }
  };

  const handleRemoveMember = async (userId: number) => {
    try {
      await groupsApi.removeGroupMember(groupId, userId);
      message.success('Участник удален успешно');
      onUpdate();
    } catch (error) {
      message.error('Ошибка при удалении участника');
    }
  };

  return (
    <div className="flex flex-col">
      <div className="p-4 border-b">
        <Button type="primary" onClick={() => setIsModalVisible(true)}>
          Добавить участника
        </Button>
      </div>

      <div className="p-4 overflow-auto">
        <Table 
          columns={columns} 
          dataSource={members} 
          rowKey="id"
          pagination={{ pageSize: 10 }}
          scroll={{ x: 800 }}
        />
      </div>

      <Modal
        title="Добавить участника"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleAddMember}>
          <Form.Item
            name="user_id"
            label="Пользователь"
            rules={[{ required: true, message: 'Выберите пользователя' }]}
          >
            <Select>
              {users.map(user => (
                <Select.Option key={user.id} value={user.id}>
                  {user.full_name || user.user_name} ({user.email})
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Добавить
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}; 