import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { MembersTab } from './components/MembersTab';
import { ProjectsTab } from './components/ProjectsTab';
import { 
  Table, 
  Button, 
  Modal, 
  Form, 
  Input, 
  message, 
  Tabs,
  Space,
  Select
} from 'antd';
import { groupsApi } from 'src/shared/api/groupsApi';
import { Group } from 'src/shared/types/groups';
import type { UserRole } from 'src/shared/types/groups';

export const GroupsManagement: FC = () => {
  const [groups, setGroups] = useState<Group[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [registerForm] = Form.useForm();

  const fetchGroups = async () => {
    try {
      const { data } = await groupsApi.getGroups();
      setGroups(data);
      if (selectedGroup) {
        const updatedGroup = data.find(group => group.id === selectedGroup.id);
        setSelectedGroup(updatedGroup || null);
      }
    } catch (error) {
      message.error('Ошибка при загрузке групп');
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  const groupColumns = [
    { title: 'Название', dataIndex: 'group_name', key: 'group_name' },
    { title: 'Описание', dataIndex: 'group_description', key: 'group_description' },
    {
      title: 'Действия',
      key: 'actions',
      render: (record: Group) => (
        <Space>
          <Button onClick={() => setSelectedGroup(record)}>
            Подробности
          </Button>
          <Button danger onClick={() => handleDeleteGroup(record.id)}>
            Удалить
          </Button>
        </Space>
      )
    }
  ];

  const handleCreateGroup = async (values: { name: string; description: string }) => {
    try {
      await groupsApi.createGroup({
        group_name: values.name,
        group_description: values.description
      });
      message.success('Группа создана успешно');
      fetchGroups();
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      message.error('Ошибка при создании группы');
    }
  };

  const handleDeleteGroup = async (groupId: number) => {
    try {
      await groupsApi.deleteGroup(groupId);
      message.success('Группа удалена успешно');
      fetchGroups();
    } catch (error) {
      message.error('Ошибка при удалении группы');
    }
  };

  const handleRegisterUser = async (values: {
    email: string;
    full_name: string;
    user_name: string;
    role: UserRole;
  }) => {
    try {
      await groupsApi.registerUser({
        email: values.email,
        full_name: values.full_name,
        user_name: values.user_name,
        role: values.role as UserRole
      });
      message.success('Пользователь успешно зарегистрирован. Пароль отправлен на указанный email');
      setIsRegisterModalVisible(false);
      registerForm.resetFields();
      fetchGroups();
    } catch (error) {
      message.error('Ошибка при регистрации пользователя');
    }
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex justify-between items-center p-6">
        <h1 className="text-2xl m-0 text-white">Управление группами</h1>
        <Space>
          <Button onClick={() => setIsRegisterModalVisible(true)}>
            Добавить пользователя
          </Button>
          <Button type="primary" onClick={() => setIsModalVisible(true)}>
            Создать группу
          </Button>
        </Space>
      </div>

      <div className="flex-1 px-6 mb-6">
        <div className="h-full max-w-[1200px] mx-auto bg-white rounded-lg shadow">
          <Table 
            columns={groupColumns} 
            dataSource={groups} 
            rowKey="id"
            pagination={{ pageSize: 10 }}
          />
        </div>
      </div>

      {selectedGroup && (
        <div className="flex-1 px-6 pb-6">
          <div className="h-full max-w-[1200px] mx-auto bg-white rounded-lg shadow">
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="Участники" key="1">
                <div className="max-h-[400px] overflow-auto">
                  <MembersTab 
                    groupId={selectedGroup.id} 
                    members={selectedGroup.users} 
                    onUpdate={fetchGroups} 
                  />
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Проекты" key="2">
                <div className="max-h-[400px] overflow-auto">
                  <ProjectsTab 
                    groupId={selectedGroup.id} 
                    projects={selectedGroup.projects} 
                    onUpdate={fetchGroups} 
                  />
                </div>
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      )}

      <Modal
        title="Создать группу"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleCreateGroup}>
          <Form.Item
            name="name"
            label="Название"
            rules={[{ required: true, message: 'Введите название группы' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Описание"
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Создать
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Регистрация пользователя"
        open={isRegisterModalVisible}
        onCancel={() => setIsRegisterModalVisible(false)}
        footer={null}
      >
        <Form form={registerForm} onFinish={handleRegisterUser} layout="vertical">
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: 'Введите email' },
              { type: 'email', message: 'Введите корректный email' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="full_name"
            label="Полное имя"
            rules={[{ required: true, message: 'Введите полное имя' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="user_name"
            label="Имя пользователя"
            rules={[{ required: true, message: 'Введите имя пользователя' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="role"
            label="Роль"
            rules={[{ required: true, message: 'Выберите роль пользователя' }]}
          >
            <Select>
              <Select.Option value="form_designer">Дизайнер форм</Select.Option>
              <Select.Option value="data_operator">Оператор</Select.Option>
              <Select.Option value="admin">Админ</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Зарегистрировать
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}; 