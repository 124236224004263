import { useContext, useEffect, useState } from 'react';
import { TableContext } from 'src/app/providers/TableProvider';
import { ActionsType } from 'src/shared/store/table/types';

export const useCheckPasteAddRow = () => {
  const { tableData, setTableData, addEmptyRows } = useContext(TableContext);
  const [selectedRowId, setSelectedRowId] = useState<number | string>('');

  const processCellValue = (cell: string) => {
    const trimmed = cell.trim();
    if (trimmed !== '' && !trimmed.includes(',') && !trimmed.includes('.') && !isNaN(Number(trimmed))) {
      return `${trimmed},0`;
    }
    return trimmed;
  };

  const checkPasteAddRow = (event: ClipboardEvent) => {
    const htmlData = event.clipboardData?.getData('text/html') || '';
    const plainData = event.clipboardData?.getData('text/plain') || '';

    const parsedData = new DOMParser().parseFromString(htmlData, 'text/html');
    const hasReactGridAttribute =
      document.body.firstElementChild?.getAttribute('data-reactgrid') === 'reactgrid-content';

    let rowsToPasteCount = 0;
    let rows: string[][] = [];

    if (hasReactGridAttribute && document.body.firstElementChild?.firstElementChild) {
      const trElements = parsedData.querySelectorAll('tr');
      rowsToPasteCount = trElements.length;
      rows = Array.from(trElements).map(tr => {
        const cells = Array.from(tr.querySelectorAll('td,th')).map(td => processCellValue(td.textContent || ''));
        return cells;
      });
    } else {
      const rawRows = plainData.replace(/(\r\n)$/, '').split('\n');
      rowsToPasteCount = rawRows.length;
      rows = rawRows.map(row =>
        row
          .split('\t')
          .map(cell => processCellValue(cell))
      );
    }


    setTableData(prev =>
      prev.map((el, i) => {
        if (+selectedRowId <= i && +selectedRowId + rowsToPasteCount > i) {
          return { ...el, actions: { ...(el.actions as ActionsType), isEdit: true } };
        }
        return el;
      }),
    );

    const rowsToInsert = tableData.length - +selectedRowId;
    const newRowsLength = rowsToInsert >= rowsToPasteCount ? 0 : rowsToPasteCount - rowsToInsert;
    if (newRowsLength) {
      addEmptyRows(newRowsLength);
    }
  };

  const checkAddRow = (e: KeyboardEvent) => {
    if (e.key !== 'Enter') return;
    if (selectedRowId === tableData.length - 1) {
      addEmptyRows(1);
    }
  };

  useEffect(() => {
    const table = document.querySelector('#table-container');
    if (!table) return;

    table.addEventListener('paste', checkPasteAddRow);
    table.addEventListener('keydown', checkAddRow);

    return () => {
      table.removeEventListener('paste', checkPasteAddRow);
      table.removeEventListener('keydown', checkAddRow);
    };
  }, [selectedRowId, tableData]);

  return { setSelectedRowId };
};
