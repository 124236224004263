import { AxiosResponse } from 'axios';
import { AUTH_URL } from 'src/shared/config';
import { Group, GroupProject, GroupUser, UserRole } from '../types/groups';
import { ApiAuth } from './ApiAuth';

interface RegisterUserData {
  email: string;
  full_name: string;
  user_name: string;
  role: UserRole;
  created_at?: string;
  updated_at?: string;
}

export const groupsApi = {
  // Groups
  getGroups: () => ApiAuth.get<Group[]>('/api/groups'),
  
  createGroup: (data: { group_name: string; group_description: string }) => 
    ApiAuth.post<Group>('/api/groups', {
      group_name: data.group_name,
      group_description: data.group_description,
      created_at: new Date().toISOString(),
      created_dt: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      users: [],
      projects: []
    }),

  deleteGroup: (id: number) => 
    ApiAuth.delete<Group>(`/api/groups/${id}`),

  // Users
  getUsers: () => ApiAuth.get<GroupUser[]>('/api/users'),
  
  addGroupMember: (groupId: number, userId: number) =>
    ApiAuth.post<void>(`/api/groups/${groupId}/users/${userId}`),
    
  removeGroupMember: (groupId: number, userId: number) =>
    ApiAuth.delete<void>(`/api/groups/${groupId}/users/${userId}`),

  // Projects
  getProjects: () => ApiAuth.get<GroupProject[]>('/api/projects'),
  
  addGroupProject: (groupId: number, projectId: number) =>
    ApiAuth.post<void>(`/api/groups/${groupId}/projects/${projectId}`),

  removeGroupProject: (groupId: number, projectId: number) =>
    ApiAuth.delete<void>(`/api/groups/${groupId}/projects/${projectId}`),

  // Auth
  registerUser: (data: Omit<RegisterUserData, 'created_at' | 'updated_at'>) =>
    ApiAuth.post<void>('/register', {
      ...data,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString()
    }),
}; 