import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import { Table, Button, Modal, Form, Select, message } from 'antd';
import type { GroupProject } from 'src/shared/types/groups';
import { groupsApi } from 'src/shared/api/groupsApi';

interface ProjectsTabProps {
  groupId: number;
  projects: GroupProject[];
  onUpdate: () => void;
}

export const ProjectsTab: FC<ProjectsTabProps> = ({ groupId, projects, onUpdate }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [availableProjects, setAvailableProjects] = useState<GroupProject[]>([]);
  const [form] = Form.useForm();

  const fetchProjects = async () => {
    try {
      const { data } = await groupsApi.getProjects();
      setAvailableProjects(data);
    } catch (error) {
      message.error('Ошибка при загрузке проектов');
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const columns = [
    { title: 'Название', dataIndex: 'project_name', key: 'project_name' },
    { title: 'Описание', dataIndex: 'project_description', key: 'project_description' },
    { title: 'Создан', dataIndex: 'created_dt', key: 'created_dt' },
    { 
      title: 'Видимость', 
      dataIndex: 'is_visible', 
      key: 'is_visible',
      render: (isVisible: boolean) => isVisible ? 'Да' : 'Нет'
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (record: GroupProject) => (
        <Button 
          danger 
          onClick={() => handleRemoveProject(record.id)}
        >
          Удалить
        </Button>
      )
    }
  ];

  const handleAddProject = async (values: { project_id: number }) => {
    try {
      await groupsApi.addGroupProject(groupId, values.project_id);
      message.success('Проект добавлен успешно');
      onUpdate();
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      message.error('Ошибка при добавлении проекта');
    }
  };

  const handleRemoveProject = async (projectId: number) => {
    try {
      await groupsApi.removeGroupProject(groupId, projectId);
      message.success('Проект удален из группы');
      onUpdate();
    } catch (error) {
      message.error('Ошибка при удалении проекта');
    }
  };

  return (
    <div className="flex flex-col">
      <div className="p-4 border-b">
        <Button type="primary" onClick={() => setIsModalVisible(true)}>
          Добавить проект
        </Button>
      </div>

      <div className="p-4 overflow-auto">
        <Table 
          columns={columns} 
          dataSource={projects} 
          rowKey="id"
          pagination={{ pageSize: 10 }}
          scroll={{ x: 800 }}
        />
      </div>

      <Modal
        title="Добавить проект"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleAddProject}>
          <Form.Item
            name="project_id"
            label="Проект"
            rules={[{ required: true, message: 'Выберите проект' }]}
          >
            <Select>
              {availableProjects.map(project => (
                <Select.Option key={project.id} value={project.id}>
                  {project.project_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Добавить
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}; 